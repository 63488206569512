import(/* webpackMode: "eager" */ "/codebuild/output/src1635139156/src/opt-multisite/apps/main-site/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src1635139156/src/opt-multisite/libs/ui/shared/theme-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1635139156/src/opt-multisite/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1635139156/src/opt-multisite/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1635139156/src/opt-multisite/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1635139156/src/opt-multisite/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src1635139156/src/opt-multisite/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src1635139156/src/opt-multisite/node_modules/next/dist/client/script.js");
